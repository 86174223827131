<template>
  <Head>
    <title>Affiliates Terms & Conditions | M-Changa Africa</title>
    <meta
      name="description"
      content="M-Changa Africa Affiliates: Terms and conditions."
    />
    <meta name="author" content="Mobi Changa" />
    <meta property="og:type" content="website" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta property="og:url" :content="this.baseURL + '/signup'" />
    <meta
      property="og:title"
      content="Terms & Conditions | M-Changa Affiliates"
    />
    <meta
      property="og:description"
      content="M-Changa Africa Affiliates: Terms & Conditions."
    />
    <meta property="fb:app_id" :content="this.facebookID" />
  </Head>
  <div>
    <section id="content">
      <div class="content-wrap py-0">
        <div class="section nobg nomargin">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="heading-block center">
                  <h2>M-Changa Affiliates Terms & Conditions</h2>
                </div>
                <p>
                  Any person or entity that participates or attempts to
                  participate in our associate sales program (the
                  <strong>"Associates Program"</strong> and such person or
                  entity,<strong>"you"</strong>, or an
                  <strong>"Associate"</strong>) must accept this Associates
                  Program Operating Agreement (this
                  <strong>"Agreement"</strong>) without change. By registering
                  for the Affiliates Program, you agree to this Agreement.
                </p>

                <h4 class="mb-1">1. Description of the Affiliates Program</h4>
                <p>
                  The Affiliates Program permits you to earn money by referring
                  fundraisers to M-Changa including; word of mouth, social media
                  shares & website links. To earn commissions, fundraisers must
                  register using unique referral links provided during
                  registration.
                </p>
                <p>
                  When fundraisers click through the Referral Links to register
                  a fundraiser, you can receive commissions for verified
                  fundraisers. In order to facilitate your advertisement of
                  M-Changa services, we may make available to you data, images,
                  text, link formats, widgets, links, marketing content, and
                  other linking tools, application program interfaces, and other
                  information in connection with the Affiliates Program.
                </p>

                <h4 class="mb-1">
                  2. Affiliates Program Compliance Requirements
                </h4>
                <p>
                  You must comply with this Agreement to participate in the
                  Affiliates Program and receive commissions.
                </p>
                <p>
                  You must promptly provide us with any information that we
                  request to verify your compliance with this Agreement.
                </p>
                <p>
                  If you violate this Agreement, or if you violate terms and
                  conditions of any other applicable M-Changa marketing program,
                  then, in addition to any other rights or remedies available to
                  us, we reserve the right to permanently withhold (and you
                  agree you will not be eligible to receive) any and all
                  commissions otherwise payable to you under this Agreement,
                  whether or not directly related to such violation without
                  further notice and without prejudice of any right of M-Changa
                  to recover damages in excess of this amount.
                </p>

                <h4 class="mb-1">3. M-Changa Fundraisers</h4>
                <p>
                  Our fundraisers are not, by virtue of your participation in
                  the Affiliates Program, your fundraisers. As between you and
                  us, all terms relating to the M-Changa fundraising platform
                  will apply to M-Changa fundraisers and may be changed at any
                  time. You will not handle or address any contacts with any of
                  our customers, and, if contacted by any of our customers for a
                  matter relating to interaction with M-Changa, you will state
                  that those fundraisers must follow contact directions with
                  M-Changa customer care.
                </p>

                <h4 class="mb-1">4. Warranties</h4>
                <p>
                  You represent, warrant, and covenant that (a) you will
                  participate in the Affiliates Program and create, maintain,
                  and operate your Site in accordance with this Agreement, (b)
                  neither your participation in the Affiliates Program nor your
                  creation, maintenance, or operation of your Site will violate
                  any applicable laws, ordinances, rules, regulations, orders,
                  licenses, permits, guidelines, codes of practice, industry
                  standards, self-regulatory rules, judgments, decisions, or
                  other requirements of any governmental authority that has
                  jurisdiction over you (including all such rules governing
                  communications, data protection, advertising, and marketing),
                  (c) you are lawfully able to enter into contracts (e.g. you
                  are not a minor or otherwise legally prevented from
                  contracting), (d) you have independently evaluated the
                  desirability of participating in the Affiliates Program and
                  are not relying on any representation, guarantee, or statement
                  other than as expressly set forth in this Agreement, (e) you
                  will not participate in the Associates Program or use any
                  other Service Offerings if you are the subject of sanctions;
                  (f) you will comply with all export and re-export restrictions
                  that may apply to goods, software, technology and services,
                  and (g) the information you provide in connection with the
                  Affiliates Program is accurate and complete at all times.
                </p>
                <p>
                  We do not make any representation, warranty, or covenant
                  regarding the amount of traffic or fees you can expect at any
                  time in connection with the Affiliates Program, and we will
                  not be liable for any actions you undertake based on your
                  expectations.
                </p>

                <h4 class="mb-1">5. Identifying Yourself as an Affiliate</h4>
                <p>
                  For affiliates opting to receive commissions, you
                  must clearly state the following: “As an M-Changa Affiliate I
                  earn from qualifying fundraisers.”. Affiliates opting to
                  donate commissions to another fundraiser should equally make
                  this clear.
                </p>
                <p>
                  Except for this disclosure, you will not make any public
                  communication with respect to this Agreement or your
                  participation in the Affiliates Program without our advance
                  written permission. You will not misrepresent or embellish our
                  relationship with you (including by expressing or implying
                  that we support, sponsor, or endorse you), or express or imply
                  any affiliation between us and you or any other person or
                  entity except as expressly permitted by this Agreement.
                </p>

                <h4 class="mb-1">6. Term and Termination</h4>
                <p>
                  The term of this Agreement will begin upon your registration
                  as an M-Changa Affiliate. Either you or we may terminate this
                  Agreement at any time, with or without cause (automatically
                  and without recourse to the courts, if permitted under
                  applicable law), by giving the other party written notice of
                  termination provided that the effective date of such
                  termination will be 7 calendar days from the date notice is
                  provided. You can provide termination notice by emailing
                  support@mchanga.africa. In addition, we may terminate this
                  Agreement immediately at any time upon written notice to you
                  for Cause. <strong>"Cause"</strong> means any of the
                  following: (a) you are in material breach of this Agreement or
                  you are in minor breach of this Agreement but you do not
                  remedy it within 7 days; (b) we believe that we may face
                  potential claims or liability in connection with your
                  participation in the Affiliates Program; (c) we believe that
                  our brand or reputation may be tarnished by you or in
                  connection with your participation in the Affiliates Program;
                  (d) we believe that we are or may become subject to tax
                  collection requirements in connection with this Agreement or
                  the activities performed by either party under this Agreement;
                  (e) we have previously terminated this Agreement with respect
                  to you or other persons that we determine are affiliated with
                  you or acting in concert with you for any reason, or (f) we
                  have terminated the Affiliates Program as we generally make it
                  available to participants. For the avoidance of doubt and
                  without limitation for purposes of the foregoing subsection
                  (a) any violation of Section 5 will be deemed a material
                  breach of this Agreement.
                </p>
                <p>
                  We may hold accrued unpaid advertising fees for a reasonable
                  period of time following termination to ensure that the
                  correct amount is paid (for example, to account for any
                  cancelations or returns).
                </p>
                <p>
                  Upon any termination of this Agreement, all rights and
                  obligations of the parties will be extinguished, including any
                  and all licenses granted in connection with this Agreement,
                  except that the rights and obligations of the parties under
                  Sections 3, 4, 5, 6, 7, 8, 10, and 11 of this Agreement and as
                  specified in the Program Policies, together with any payable
                  but unpaid payment obligations under this Agreement, will
                  survive the termination of this Agreement. No termination of
                  this Agreement will relieve either party for any liability for
                  any breach of, or liability accruing under, this Agreement
                  prior to termination.
                </p>

                <h4 class="mb-1">7. Disclaimers</h4>
                <p>
                  THE AFFILIATES PROGRAM, THE M-CHANGA SITE, ANY PRODUCTS AND
                  SERVICES OFFERED ON THE M-CHANGA SITE, ANY SPECIAL LINKS, LINK
                  FORMATS, CONTENT, THE PRODUCT ADVERTISING API, DATA FEED,
                  PRODUCT ADVERTISING CONTENT, OUR AND OUR AFFILIATES’ DOMAIN
                  NAMES, TRADEMARKS AND LOGOS, AND ALL TECHNOLOGY, SOFTWARE,
                  FUNCTIONS, MATERIALS, DATA, IMAGES, TEXT, AND OTHER
                  INTELLECTUAL PROPERTY RIGHTS, INFORMATION AND CONTENT PROVIDED
                  OR USED BY OR ON BEHALF OF US OR OUR AFFILIATES OR LICENSORS
                  IN CONNECTION WITH THE ASSOCIATES PROGRAM (COLLECTIVELY THE
                  <strong>"SERVICE OFFERINGS"</strong>) ARE PROVIDED “AS IS” AND
                  “AS AVAILABLE”. NEITHER WE NOR ANY OF OUR AFFILIATES OR
                  LICENSORS MAKE ANY REPRESENTATION OR WARRANTY OF ANY KIND,
                  WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH
                  RESPECT TO THE SERVICE OFFERINGS. WE AND OUR AFFILIATES AND
                  LICENSORS DISCLAIM ALL WARRANTIES WITH RESPECT TO THE SERVICE
                  OFFERINGS, INCLUDING ANY IMPLIED WARRANTIES OF TITLE,
                  MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A
                  PARTICULAR PURPOSE, OR NON-INFRINGEMENT AND ANY WARRANTIES
                  ARISING OUT OF ANY LAW, CUSTOM, COURSE OF DEALING,
                  PERFORMANCE, OR TRADE USAGE. WE MAY DISCONTINUE ANY SERVICE
                  OFFERING, OR MAY CHANGE THE NATURE, FEATURES, FUNCTIONS,
                  SCOPE, OR OPERATION OF ANY SERVICE OFFERING, AT ANY TIME AND
                  FROM TIME TO TIME. NEITHER WE NOR ANY OF OUR AFFILIATES OR
                  LICENSORS WARRANT THAT THE SERVICE OFFERINGS WILL CONTINUE TO
                  BE PROVIDED, WILL FUNCTION AS DESCRIBED, CONSISTENTLY OR IN
                  ANY PARTICULAR MANNER, OR WILL BE UNINTERRUPTED, ACCURATE,
                  ERROR FREE, OR FREE OF HARMFUL COMPONENTS. NEITHER WE NOR ANY
                  OF OUR AFFILIATES OR LICENSORS WILL BE RESPONSIBLE FOR (A) ANY
                  ERRORS, INACCURACIES, VIRUSES, MALICIOUS SOFTWARE, OR SERVICE
                  INTERRUPTIONS, INCLUDING POWER OUTAGES OR SYSTEM FAILURES OR
                  (B) ANY UNAUTHORIZED ACCESS TO OR ALTERATION OF, OR DELETION,
                  DESTRUCTION, DAMAGE, OR LOSS OF, YOUR SITE OR ANY DATA,
                  IMAGES, TEXT, OR OTHER INFORMATION OR CONTENT. NO ADVICE OR
                  INFORMATION OBTAINED BY YOU FROM US OR FROM ANY OTHER PERSON
                  OR ENTITY OR THROUGH THE SERVICE OFFERINGS WILL CREATE ANY
                  WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT. FURTHER,
                  NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE
                  RESPONSIBLE FOR ANY COMPENSATION, REIMBURSEMENT, OR DAMAGES
                  ARISING IN CONNECTION WITH (X) ANY LOSS OF PROSPECTIVE PROFITS
                  OR REVENUE, ANTICIPATED SALES, GOODWILL, OR OTHER BENEFITS,
                  (Y) ANY INVESTMENTS, EXPENDITURES, OR COMMITMENTS BY YOU IN
                  CONNECTION WITH YOUR PARTICIPATION IN THE ASSOCIATES PROGRAM,
                  OR (Z) ANY TERMINATION OR SUSPENSION OF YOUR PARTICIPATION IN
                  THE ASSOCIATES PROGRAM. NOTHING IN THIS PARAGRAPH WILL OPERATE
                  TO EXCLUDE OR LIMIT WARRANTIES, LIABILITIES, OR
                  REPRESENTATIONS THAT CANNOT BE EXCLUDED OR LIMITED UNDER
                  APPLICABLE LAW.
                </p>

                <h4 class="mb-1">8. Limitations on Liability</h4>
                <p>
                  NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE
                  LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
                  EXEMPLARY DAMAGES (INCLUDING ANY LOSS OF REVENUE, PROFITS,
                  GOODWILL, USE, OR DATA) ARISING IN CONNECTION WITH THE SERVICE
                  OFFERINGS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                  THOSE DAMAGES. FURTHER, OUR AGGREGATE LIABILITY ARISING IN
                  CONNECTION WITH THE SERVICE OFFERINGS WILL NOT EXCEED THE
                  TOTAL FEES PAID OR PAYABLE TO YOU UNDER THIS AGREEMENT IN THE
                  TWELVE MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH THE
                  EVENT GIVING RISE TO THE MOST RECENT CLAIM OF LIABILITY
                  OCCURRED. YOU HEREBY WAIVE ANY RIGHT OR REMEDY IN EQUITY,
                  INCLUDING THE RIGHT TO SEEK SPECIFIC PERFORMANCE, INJUNCTIVE
                  OR OTHER EQUITABLE RELIEF IN CONNECTION WITH THIS AGREEMENT.
                  NOTHING IN THIS PARAGRAPH WILL OPERATE TO LIMIT LIABILITIES
                  THAT CANNOT BE LIMITED UNDER APPLICABLE LAW.
                </p>

                <h4 class="mb-1">9. Indemnification</h4>
                <p>
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE WILL
                  HAVE NO LIABILITY FOR ANY MATTER DIRECTLY OR INDIRECTLY
                  RELATING TO THE CREATION, MAINTENANCE, OR OPERATION OF YOUR
                  SITE (INCLUDING YOUR USE OF ANY SERVICE OFFERING) OR YOUR
                  VIOLATION OF THIS AGREEMENT, AND YOU AGREE TO DEFEND,
                  INDEMNIFY, AND HOLD US, OUR AFFILIATES AND LICENSORS, AND OUR
                  AND THEIR RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS, AND
                  REPRESENTATIVES, HARMLESS FROM AND AGAINST ALL CLAIMS,
                  DAMAGES, LOSSES, LIABILITIES, COSTS, AND EXPENSES (INCLUDING
                  ATTORNEYS’ FEES) RELATING TO (A) YOUR SITE OR ANY MATERIALS
                  THAT APPEAR ON YOUR SITE, INCLUDING THE COMBINATION OF YOUR
                  SITE OR THOSE MATERIALS WITH OTHER APPLICATIONS, CONTENT, OR
                  PROCESSES, (B) THE USE, DEVELOPMENT, DESIGN, MANUFACTURE,
                  PRODUCTION, ADVERTISING, PROMOTION, OR MARKETING OF YOUR SITE
                  OR ANY MATERIALS THAT APPEAR ON OR WITHIN YOUR SITE, (C) YOUR
                  USE OF ANY SERVICE OFFERING, WHETHER OR NOT SUCH USE IS
                  AUTHORIZED BY OR VIOLATES THIS AGREEMENT OR APPLICABLE LAW,
                  (D) YOUR VIOLATION OF ANY TERM OR CONDITION OF THIS AGREEMENT
                  (INCLUDING ANY PROGRAM POLICY), OR (E) YOUR OR YOUR EMPLOYEES'
                  OR CONTRACTORS’ NEGLIGENCE OR WILLFUL MISCONDUCT.
                </p>

                <h4 class="mb-1">10. Description</h4>
                <p>
                  We reserve the right to modify any of the terms and conditions
                  contained in this Agreement at any time and in our sole
                  discretion by posting a change notice, revised Agreement, or
                  revised Program Policy on the Associates Site or by sending
                  notice of such modification to you by SMS. The effective date
                  of such change will be the date specified, and be no less than
                  seven calendar days from the date the notice is provided. YOUR
                  CONTINUED PARTICIPATION IN THE AFFILIATES PROGRAM FOLLOWING
                  THE EFFECTIVE DATE OF SUCH CHANGE WILL CONSTITUTE YOUR
                  ACCEPTANCE OF THE MODIFICATIONS. IF ANY MODIFICATION IS
                  UNACCEPTABLE TO YOU, YOUR ONLY RECOURSE IS TO TERMINATE THIS
                  AGREEMENT IN ACCORDANCE WITH SECTION 6.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import { Head } from "@vueuse/head";
import Footer from "../components/Footer/index.vue";

export default {
  components: {
    Head,
    Footer,
  },
};
</script>
